import { css, html, LitElement } from "lit";
import { url } from '../lit-directives.js';
import gps_location_marker_greyscale from './images/gps-location-marker-greyscale.svg';
import saved_location_marker_greyscale from './images/saved-location-marker-greyscale.svg';
import { app, isSmartPhone, isSmartPhoneBrowser, isOffshoreApp } from "../../platform.js";
import { classMap } from "lit/directives/class-map.js";
import pin from './images/pin.svg';
import close from './images/close-icon-transparent-background.svg';

const
    gettext = window.gettext,
    save_text = gettext("Save"),
    measure_text = gettext("Measure"),
    lat_text = gettext("Latitude"),
    lon_text = gettext("Longitude"),
    gps_location_text = gettext("Current Location"),
    location_current_text = gettext(" (Active)"),
    saved_location_text = gettext("Saved Location"),
    set_location_text = gettext("Set as Active");

// This is almost the same as atlas.config.GRIB_FIELD_ORDER but not quite; did we mean to do that?
const GRIB_FIELD_ORDER = [
    'WindSpeed',
    'WindDirection',
    'Pressure',
    'Gust',
    'CAPE',
    'WaveHeight',
    'WaveDirection',
    'WavePeriod',
    'Rain',
    'Cloud',
    'AirTemp',
    'CurrentSpeed',
    'CurrentDirection',
    'SeaTemp',
];

export default class AtlasTooltipContent extends LitElement {
    static get properties() {
        const result = {
            lat_formatted: { type: String },
            lon_formatted: { type: String }
        };
        for (const key of GRIB_FIELD_ORDER) {
            result[`gribfield_${key}_label`] = { type: String };
            result[`gribfield_${key}_formatted`] = { type: String }
        }
        return result;
    }

    constructor() {
        super();

        // Reactive Properties
        this.lat_formatted = '';
        this.lon_formatted = '';
        for (const key of GRIB_FIELD_ORDER) {
            this[`gribfield_${key}_label`] = '';
            this[`gribfield_${key}_formatted`] = '';
        }


        // Internal
        this.publicPage = 0;
        this.locationMarkers = [];
        this.map1OptionalTitle = '';
        this.map2OptionalTitle = '';
        this.isCurrentLocation = false;
        this.disableSave = false;
        this.location = null;
        this.closed = false;
    }

    // This is needed to override the default Atlas handler which closes the tooltip
    preventPropagation(event) {
        event.stopPropagation();
    }

    get parentMap() {
        // We expect to have an assigned property `this.map` which is an instance of `Atlas.Map`
        // (look for 'atlas-tooltip-content' in atlas.map.js)
        // but we want an instance of AtlasMap, which is a little more complicated
        return [
            PWGMap.map1, PWGMap.map2, PWGMap[0]
        ].filter(x => x?._atlasMap === this.map)[0];
    }

    connectedCallback() {
        super.connectedCallback();
        if (isOffshoreApp) {
            this.disableSave = true;
        } else {
            this.publicPage = $j(".public").length;
            if (this.publicPage) {
                this.disableSave = true;
                this.locationMarkers = [];
            } else {
                this.locationMarkers = app?.getData("Locations") ?? window.pw_getLocations?.() ?? [];
            }
            this.checkPosition();
        }
        this.map.addListener('click', (event) => {
            if (this.map.tooltipsEnabled) {
                const isPinned = this.map.tooltipPin !== null;
                if (this.closed) {
                    // Clicking the map while the tooltip is closed should result in it following the cursor
                    this.parentMap._atlasMap.setTooltipPin(null);
                    this.classList.toggle('pinned', false);
                    this.closed = false;
                } else {
                    this.classList.toggle('pinned', isPinned);
                }
            }
        });
    }

    measure(event) {
        event.stopPropagation();
        this.dispatchEvent(new CustomEvent("initialise-ruler", {
            detail: {
                p: this.map.tooltipPin,
                map: this.parentMap
            },
            bubbles: true,
            composed: true
        }));
    }

    save(event) {
        if (!this.publicPage && !isOffshoreApp) {
            this.dispatchEvent(new CustomEvent("map-save-location", {
                detail: {
                    p: this.map.tooltipPin
                },
                bubbles: true,
                composed: true
            }));
        }
    }

    close(event) {
        event.stopPropagation();
        this.map.tooltipClosed = true;
        this.closed = true;
    }

    checkPosition() {
        if (!isOffshoreApp) {
            if (this.publicPage) {
                this.locationMarkers = [];
            } else {
                this.locationMarkers = app?.getData("Locations") ?? window.pw_getLocations?.() ?? [];
            }
            this.map1OptionalTitle = this.map2OptionalTitle = '';
            this.isCurrentLocation = false;
            if (this.map.tooltipPin) {
                this.classList.toggle('pinned', true);
                const p = this.map.tooltipPin;
                let gps;
                if (this.parentMap?.PW_gpsPositionMarker) {
                    gps = { lat: +this.parentMap.PW_gpsPositionMarker.lat.toFixed(3), lon: +this.parentMap.PW_gpsPositionMarker.lon.toFixed(3) };
                }
                if (gps && p.lat === gps.lat && p.lon === gps.lon) {
                    if (this.parentMap === PWGMap.map1) {
                        this.map1OptionalTitle = gps_location_text
                    } else {
                        this.map2OptionalTitle = gps_location_text;
                    }
                }
                if (!this.publicPage) {
                    if (this.locationMarkers) {
                        for (const location of this.locationMarkers) {
                            if (location.lat == p.lat && location.lon == p.lon) {
                                this.location = location;
                                if (this.parentMap === PWGMap.map1) {
                                    this.map1OptionalTitle = isSmartPhone ? location.label : location.name;
                                } else {
                                    this.map2OptionalTitle = isSmartPhone ? location.label : location.name;
                                }
                                if (isSmartPhone) {
                                    this.isCurrentLocation = app.getSetting('App_CurrentLocation') === location.id;
                                } else {
                                    this.isCurrentLocation = location.id === pw_getCurrentLocation().id;
                                }
                            } else {
                                this.disableSave = false;
                            }
                        }
                    }
                }
            }
        }
    }

    setActiveLocation() {
        if (!isOffshoreApp) {
            if (app) {
                app.setSetting("App_CurrentLocation", this.location.id);
                location.reload();
            } else {
                pw_setCurrentLocation(this.location);
                if (window.pw_updatePageForNewLocation) {
                    pw_updatePageForNewLocation(this.location);
                }
                this.requestUpdate();
            }
        }
    }

    getTimestamp() {
        if ($j('pw-map-slider').length) {
            if ($j('pw-map-slider')[0].noGMT) {
                return [$j('pw-map-slider')[0].formatTimestamp($j('pw-map-slider').prop('value')), ''];
            } else {
                let timestamp = $j('pw-map-slider')[0].formatTimestamp($j('pw-map-slider').prop('value'));
                const index = timestamp.search("GMT") - 7; // index of the space before the time so that we can split the string in two
                timestamp = [timestamp.slice(0, index), timestamp.slice(index)];
                return timestamp;
            }
        } else {
            const d = new Date;
            const formattedDate = `${window.GET_DAY_NAME_3(d.getDay())} ${d.getDate()} ${window.GET_MONTH_NAME_3(d.getMonth())} ${("00" + d.getHours()).slice(-2)}:${("00" + d.getMinutes()).slice(-2)}`;
            return [formattedDate, ''];
        }
    }

    unpinTooltip() {
        this.classList.remove("pinned");
    }

    renderField(key) {
        let label = this[`gribfield_${key}_label`],
            formatted = this[`gribfield_${key}_formatted`];
        return label ? html`
              <div class='tooltip-content'>
                <span>${label}</span>
                <span>${formatted}</span>
              </div>
            `: ``;
    }

    renderFields() {
        return GRIB_FIELD_ORDER.map(key => this.renderField(key));
    }

    update(changedProperties) {
        this.checkPosition();
        super.update(changedProperties);
    }

    render() {
        let optionalTitle = this.parentMap === PWGMap.map1 ? this.map1OptionalTitle : this.map2OptionalTitle
        if (optionalTitle) this.disableSave = true;
        const gpsLocationSelected = optionalTitle === gps_location_text;

        return html`
            <div class='atlas-tooltip-wrapper'
                @mousedown="${this.unpinTooltip}"
                @pointerdown="${this.unpinTooltip}"
                @touchdown="${this.unpinTooltip}"
                @touchstart="${this.unpinTooltip}">
                <span class="icon"
                    @mousedown="${this.close}"
                    @pointerdown="${this.close}"
                    @touchdown="${this.close}"
                    @touchstart="${this.close}"></span>
                <div class="${classMap({ "marker-title-display": true, "marker-title-display-active": optionalTitle })}">
                    <div class="marker-title">${optionalTitle}</div>
                    <div class="marker-subtitle">
                        <span class="${classMap({
            "gps-location": gpsLocationSelected,
            "saved-location": !gpsLocationSelected
        })}"></span>
                        <div>${gpsLocationSelected ? 'GPS' : saved_location_text}</div>
                        ${this.isCurrentLocation ? html`
                            <div>${location_current_text}</div>` :
                !gpsLocationSelected ? html`
                            <div class="set-location"
                                @mousedown="${this.preventPropagation}"
                                @pointerdown="${this.preventPropagation}"
                                @touchdown="${this.preventPropagation}"
                                @touchstart="${this.preventPropagation}"
                                @mouseup="${this.setActiveLocation}">${set_location_text}</div>` :
                    ""}
                    </div>
                </div>
                <div class='tooltip-content'>
                    <div class='tooltip-timestamp'>${this.getTimestamp()}</div>
                </div>
                ${this.renderFields()}
                <div class='tooltip-content'>
                    <span>${lat_text}</span>
                    <span>${this.lat_formatted}</span>
                </div>
                <div class='tooltip-content'>
                    <span>${lon_text}</span>
                    <span>${this.lon_formatted}</span>
                </div>
                <div class='tooltip-buttons'>
                    <span class="${classMap({ "save": true, "disable-save": this.disableSave && !gpsLocationSelected })}"
                        @mousedown="${this.preventPropagation}"
                        @pointerdown="${this.preventPropagation}"
                        @touchdown="${this.preventPropagation}"
                        @touchstart="${this.preventPropagation}"
                        @mouseup="${this.save}">
                            ${save_text}
                    </span>
                    <span class="${classMap({ "measure": true, "measure-only": this.disableSave && !gpsLocationSelected })}"
                        @mousedown="${this.preventPropagation}"
                        @pointerdown="${this.preventPropagation}"
                        @touchdown="${this.preventPropagation}"
                        @touchstart="${this.preventPropagation}"
                        @mouseup="${this.measure}">
                            ${measure_text}
                    </span>
                </div>
            </div>
        `
    }

    static get styles() {
        const s = css`
            :host {
                display: block;
                pointer-events: none;
                background: rgba(26, 47, 67, 0.90) !important;
                border-radius: 8px;
                position: relative;
                z-index: 9999;
            }

            :host(.pinned) {
                pointer-events: auto;
            }

            .atlas-tooltip-wrapper {
                padding: 6px;
                width: 176px;
                box-sizing: border-box;
            }

            .icon {
                background: ${url(close)} no-repeat center;
                background-size: 12px;
                display: inline-block;
                position: absolute;
                top: 8px;
                right: 8px;
                width: 15px;
                height: 15px;
                float: right;
                cursor: pointer;
            }

            :host(:not(.pinned)) .icon {
                background-image: ${url(pin)};
                background-size: 15px;
                filter: invert(1);
            }

            .tooltip-content {
                display: flex;
                width: 100%;
            }

            .tooltip-content > span {
                display: inline-block;
                width: 50%;
                font-size: 14px;
                line-height: 17px;
            }

            .tooltip-content>span {
                overflow: hidden;
                -webkit-mask-image: -webkit-linear-gradient(left, black 80%, transparent 100%);
                mask-image: linear-gradient(to right, black 80%, transparent 100%);
            }

            .tooltip-content>span:nth-child(2) {
                font-weight: 600;
            }

            .tooltip-buttons {
                margin-top: 6px;
                display: flex;
                justify-content: space-between;
            }

            .save, .measure {
                padding: 6px 0px 5px;
                line-height: 12px;
                display: flex;
                border: 1px solid rgba(255, 255, 255, 0.30);
                border-radius: 11.5px;
                font-size: 12px;
                width: 40%;
                justify-content: center;
                cursor: pointer;
            }

            .disable-save {
                display: none;
            }

            .measure-only {
                width:100%;
            }

            .marker-title-display {
                display: none;
                width: 100%;
            }

            .marker-title-display-active {
                display: block;
            }

            .marker-title {
                font-weight: 700;
                font-size: 16px;
                width: calc(100% - 20px);
                overflow-x: hidden;
                -webkit-mask-image: -webkit-linear-gradient(left, black 85%, transparent 100%);
            }

            .marker-subtitle {
                opacity: 65%;
                font-size: 12px;
                margin: 5px 0px;
                position: relative;
            }

            .marker-subtitle > span {
                position: absolute;
                background-size: 12px;
                width: 12px;
                height: 12px;
            }

            .marker-subtitle > div {
                margin: 5px 0px 0px 20px;
            }

            .gps-location {
                background: ${url(gps_location_marker_greyscale)} no-repeat center;
            }

            .saved-location {
                top: calc(50% - 6px);
                background: ${url(saved_location_marker_greyscale)} no-repeat center;
            }

            .set-location {
                font-weight: 600;
                cursor: pointer;
                text-decoration: underline;
            }

        `
        const cssResult = [s];

        if (isSmartPhone || isSmartPhoneBrowser) {
            cssResult.push(
                css`
                    .marker-subtitle, .marker-subtitle button {
                        font-size: 14px;
                    }
                    .marker-subtitle > span {
                        background-size: 14px;
                        width: 14px;
                        height: 14px;
                    }

                    .save, .measure {
                        white-space: normal;
                        text-align: center;
                        align-items: center;
                    }

                `
            )
        }

        return cssResult;
    }

}
