const g_convertables = {};
const g_conversions = {};
const g_conversions_from = {};
const g_conversions_to = {};

class Convertable {
    constructor(options) {
        options = options ?? {};
        if (!('key' in options)) {
            throw new Error('Convertable: key required');
        }
        for (const key in options) {
            this[key] = options[key];
        }
        if (!this.label) this.label = '';
        if (!this.symbol) this.symbol = '';
        g_convertables[this.key] = this;
    }
}

class Field {
    constructor(options) {
        options = options ?? {};
        for (const key in options) {
            this[key] = options[key];
        }
    }
}

const use_fields = true;
const FIELD_TYPE = use_fields ? Field : Convertable;
export function make_field(options) {
    return use_fields
        ? new Field(options)
        : options.unit;
}
export function GET_FIELD_UNIT(field) {
    return use_fields
        ? field?.unit
        : field;
}
function merge_field(field, override) {
    return use_fields
        ? make_field({ ...field, ...override })
        : override;
}



export const any_number = new Convertable({key:'any_number'});

export function define_unit(options) {
    if (!options.format) {
        throw new Error(`Unit needs a 'format' option: ${JSON.stringify(options)}`);
    }
    const unit = new Convertable(options);
    add_conversion(unit, any_number, identity);
    return unit;
}

export function define_object(options) {
    return new Convertable(options);
}

export function define_format(options) {
    const format = new Convertable(options);
    format.format = format;
    return format;
}

function _define_convertables(prefix, map, construct) {
    if (typeof prefix !== 'string') {
        throw new Error('_define_convertables: Invalid prefix');
    }
    for (const key in map) {
        if (!('key' in map[key])) {
            map[key].key = `${prefix}.${key}`
        }
        map[key] = construct(map[key]);
    }
    return map;
}

export function define_units(prefix, map) {
    return _define_convertables(prefix, map, define_unit);
}
export function define_objects(prefix, map) {
    return _define_convertables(prefix, map, define_object);
}
export function define_formats(prefix, map) {
    return _define_convertables(prefix, map, define_format);
}

export function identity(value) {
    return value;
}

export function alias(value) {
    return value;
}

export function alias_unit(target, unit) {
    add_conversion(target, unit, alias);
}

export function fully_alias_unit(target, unit) {
    alias_unit(target, unit);
    for (const key in unit) {
        if (key !== 'key') {
            Object.defineProperty(target, key, {
                enumerable: true,
                configurable: true,
                get:() => unit[key]
            });
        }
    }
}

export function add_conversion(from, to, fn) {
    _validate_keys(from, to);
    return _add_conversion(from.key, to.key, fn);
}

export function get_conversion(from, to) {
    _validate_keys(from, to);
    return from === to ? alias : _derive_conversion(from.key, to.key);
}

export function remove_conversions(unit) {
    if (!unit.key) throw new Error("unit.key missing");
    _remove_conversions(unit.key);
}

export function make_fields_converter_old(from_fields, to_fields, remap, update_context) {
    if (remap) {
        from_fields = remap(from_fields);
    }
    class Adapter {
        constructor(value, context) {
            this._src = remap ? remap(value) : value;
            this._context = update_context ? update_context(value, context) : context;
        }
        collapse() {
            const result = {};
            for (let key in to_fields) {
                result[key] = this[key]?.collapse ? this[key].collapse() : this[key];
            }
            return result;
        }
    }
    for (const key in to_fields) {
        const to_unit = GET_FIELD_UNIT(to_fields[key]);
        const from_unit = GET_FIELD_UNIT(from_fields[key]);
        if (!from_unit) {
            continue;
        }
        let converter = null;
        if ((from_unit instanceof Convertable) && (to_unit instanceof Convertable)) {
            converter = get_conversion(from_unit, to_unit);
        } else {
            // convert subobject
            let identical_objects = false;
            if (Object.keys(to_unit).length === Object.keys(from_unit).length) {
                identical_objects = true;
                for (let subkey of Object.keys(to_unit)) {
                    if (to_unit[subkey] !== from_unit[subkey]) {
                        identical_objects = false;
                        break;
                    }
                }
            }
            if (identical_objects) {
                converter = identity;
            } else {
                converter = make_fields_converter(from_unit, to_unit);
            }
        }
        if (!converter) {
            console.error("no converter");
            continue;
        }
        if (converter === alias || converter === identity) {
            Object.defineProperty(Adapter.prototype, key, {
                enumerable: true,
                get() { return this._src[key]; }
            });
        } else {
            Object.defineProperty(Adapter.prototype, key, {
                enumerable: true,
                get() {
                    const src_value = this._src[key] ?? null;
                    if (src_value === null) {
                        return null;
                    }
                    try {
                        return converter(src_value, this._context, from_unit, to_unit);
                    } catch (ex) {
                        console.error(ex);
                    }
                }
            });
        }
    }
    return (value, context) => { return new Adapter(value, context) };
}

export function make_fields_converter(from_fields, to_fields, remap, update_context) {
    if (remap) {
        from_fields = remap(from_fields);
    }
    class Adapter {
        constructor(value, context) {
            this._src = remap ? remap(value) : value;
            this._cached = {};
            this._context = update_context ? update_context(value, context) : context;
        }
        collapse() {
            const result = {};
            for (let key in to_fields) {
                result[key] = this[key]?.collapse ? this[key].collapse() : this[key];
            }
            return result;
        }
    }
    for (const key in to_fields) {
        const to_unit = GET_FIELD_UNIT(to_fields[key]);
        const from_unit = GET_FIELD_UNIT(from_fields[key]);
        if (!from_unit) {
            continue;
        }
        Object.defineProperty(Adapter.prototype, key, {
            enumerable: true,
            get() {
                const src_value = this._src[key] ?? null;
                let converted_value = null;
                if ((from_unit instanceof Convertable) && (to_unit instanceof Convertable)) {
                    converted_value = convert(src_value, from_unit, to_unit, this._context);
                } else {
                    // convert subobject
                    let identical_objects = false;
                    if (Object.keys(to_unit).length === Object.keys(from_unit).length) {
                        identical_objects = true;
                        for (let subkey of Object.keys(to_unit)) {
                            if (to_unit[subkey] !== from_unit[subkey]) {
                                identical_objects = false;
                                break;
                            }
                        }
                    }
                    if (identical_objects) {
                        converted_value = src_value;
                    } else {
                        const converter = make_fields_converter(from_unit, to_unit);
                        converted_value = converter(src_value, this._context, from_unit, to_unit);
                    }
                }
                Object.defineProperty(this, key, {
                    enumerable:true,
                    get() {
                        return converted_value;
                    }
                });
                return converted_value;
            }
        });
    }
    return (value, context) => { return new Adapter(value, context) };
}

export function make_object_converter(from, to, remapper, update_context) {
    return make_fields_converter(from.fields, to.fields, remapper, update_context);
}

export function add_object_conversion(from, to, options) {
    const {remap, update_context} = options ?? {};
    return add_conversion(from, to, make_object_converter(from, to, remap, update_context));
}

export function convert(value, from, to, context) {
    if (!from) throw new Error('convert "from" required');
    if (!to) throw new Error('convert "to" required');
    try {
        if (from === to || value === null || value === undefined) {
            return value;
        }
        const conversion = get_conversion(from, to);
        return conversion(value, context, from, to);
    } catch (ex) {
        throw new Error(`Conversion failed: ${value} ${from.key} to ${to.key}, exception: ${ex}`);
    }
}

export function render_from_lit(value) {
    let result = '';
    if (value instanceof Array) {
        for (let i = 0; i < value.length; i++) {
            result += render_from_lit(value[i]);
        }
    } else if (value.render) {
        result += render_from_lit(value[i].render());
    } else {
        result = value;
    }
    return result;
}

export function formatted_fields(fields, overrides) {
    overrides = overrides ?? {};
    const result_fields = {};
    for (let key of Object.keys(fields)) {
        const override = overrides[key];
        const field = fields[key];
        if (field instanceof FIELD_TYPE) {
            if (override) {
                result_fields[key] = merge_field(field, override);
            } else {
                result_fields[key] = merge_field(field, make_field({unit:GET_FIELD_UNIT(field).format}));
            }
        } else if (field instanceof Object) {
            result_fields[key] = formatted_fields(field, override);
        }
    }
    return result_fields;
}

// internal

function _add_conversion(from_key, to_key, fn) {
    if (fn === alias) {
        _add_conversion_to_map(to_key, from_key, fn);
    }
    return _add_conversion_to_map(from_key, to_key, fn);
}

function _add_conversion_to_map(from_key, to_key, fn) {
    if (!g_conversions_from[from_key]) {
        g_conversions_from[from_key] = {};
    }
    if (!g_conversions_to[to_key]) {
        g_conversions_to[to_key] = {};
    }
    g_conversions_from[from_key][to_key] = 1;
    g_conversions_to[to_key][from_key] = 1;
    return g_conversions[`${from_key}->${to_key}`] = fn;
}

function _remove_conversions(unit_key) {
    if (g_conversions_from[unit_key]) {
        for (const to_key of Object.keys(g_conversions_from[unit_key])) {
            if (to_key !== 'any_number') {
                _remove_conversion_from_map(unit_key, to_key);
            }
        }
    }
    if (g_conversions_to[unit_key]) {
        for (const from_key of Object.keys(g_conversions_to[unit_key])) {
            _remove_conversion_from_map(from_key, unit_key);
        }
    }
}

function _remove_conversion_from_map(from_key, to_key) {
    if (g_conversions_from[from_key]) {
        delete g_conversions_from[from_key][to_key];
    }
    if (g_conversions_to[to_key]) {
        delete g_conversions_to[to_key][from_key];
    }
    delete g_conversions[`${from_key}->${to_key}`];
}

function _lookup_conversion_nullable(from_key, to_key) {
    return g_conversions[`${from_key}->${to_key}`] ?? null;
}

function _conversion_targets(from_key) {
    return Object.keys(g_conversions_from[from_key] ?? {});
}

function _validate_keys(from, to) {
    if (!from.key) throw new Error("from.key missing");
    if (!to.key) throw new Error("to.key missing");
}

function _derive_conversion_nullable(from_key, to_key, visited) {
    const fn = _lookup_conversion_nullable(from_key, to_key);
    if (fn || visited[from_key]) return fn;
    visited[from_key] = 1;
    for (let indirect_key of _conversion_targets(from_key)) {
        const second = _derive_conversion_nullable(indirect_key, to_key, visited);
        if (second) {
            let converter;
            const first = _lookup_conversion_nullable(from_key, indirect_key); // never null, came from loop
            if (first !== alias && first !== identity && second !== alias && second !== identity) {
                const indirect = g_convertables[indirect_key];
                converter = (value, context, from, to) => second(first(value, context, from, indirect), context, indirect, to);
            } else {
                converter = second === alias || second === identity ? first : second;
            }
            return _add_conversion(from_key, to_key, converter);
        }
    }
    return null;
}

function _derive_conversion(from_key, to_key) {
    const conversion = _derive_conversion_nullable(from_key, to_key, {});
    if (!conversion) {
        throw new Error(`Couldn't find conversion from ${from_key} to ${to_key}`);
    }
    return conversion;
}